import request from '@/common/axios';

// 查询设置
export function fetchPauseSetting() {
    return request({
        url: '/web/admin/userPause/getUserPauseSetting',
        method: 'POST',
        data:{}
    });
}

// 修改配置
export function updatePauseSetting(data) {
    return request({
        url: '/web/admin/userPause/updateUserPauseSetting',
        method: 'POST',
        data: data
    });
}