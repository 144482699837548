<template>
  <div>
    <detail-header></detail-header>
    <div class="section">
      <h2 class="title">套餐暂停</h2>
      <el-form
        ref="form"
        :model="setting"
        :rules="ruleValidate"
        label-width="110px"
      >
        <el-row>
          <el-col :offset="8" :span="8">
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker
                v-model="setting.startTime"
                type="datetime"
                value-format="x"
                placeholder="请选择开始时间"
              />
            </el-form-item>
          </el-col>

          <el-col :offset="8" :span="8">
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                v-model="setting.endTime"
                type="datetime"
                value-format="x"
                placeholder="请选择结束时间"
              />
            </el-form-item>
          </el-col>

          <el-col :offset="8" :span="8">
            <el-form-item label="暂停天数" prop="pauseDay">
              <el-input
                placeholder="请输入天数"
                v-model="setting.pauseDay"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :offset="8" :span="8">
            <el-form-item label="是否开启" prop="status">
              <el-checkbox
                v-model="setting.status"
                true-label="1"
                false-label="2"
                label="开启"
                size="large"
              ></el-checkbox>
            </el-form-item>
          </el-col>

          <el-col style="text-align:center;">
            <el-button type="primary" @click="doSave">保存</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { updatePauseSetting, fetchPauseSetting } from "@/api/userPause";

export default {
  data() {
    return {
      setting: {},
      ruleValidate: {
        startTime: [
          {
            required: true,
            message: "请选择开始时间",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择结束时间",
          },
        ],
        pauseDay: [
          {
            required: true,
            message: "请选择可暂停天数",
          },
        ],
      },
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    doSave() {
      this.$refs["form"].validate((res) => {
        if (res) {
          updatePauseSetting(this.setting).then((res) => {
            this.$message.success("操作成功");
            this.getData();
          });
        }
      });
    },
    getData() {
      fetchPauseSetting().then((res) => {
        this.setting = res.data.data || {};
        this.setting.status= this.setting.status+"";
      });
    },
  },
};
</script>

<style scoped>
.section {
  border-bottom: 1px solid #e1e1e1;
  padding: 30px 10px;
}
.section .title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}
</style>